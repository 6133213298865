@import url("fonts/fonts.css");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&family=Space+Mono&display=swap');

body {
  font-family: "SF Pro Display";
  font-weight: 400;
  padding: 0 !important;
}

.App {
  text-align: center;
}

.text-color {
  color: #1170c9;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
input,
button,
select,
div,
li {
  font-family: "SF Pro Display";
}

h1 {
  font-size: 48px !important;
  font-weight: 700 !important;
  line-height: 57px !important;
  letter-spacing: 0.02em !important;
}

h2 {
  font-size: 36px;
  font-weight: 700;
}

img {
  max-width: 100%;
}

/*********************************** Navigation ****************************/
header nav.navbar {
  padding: 35px 0 10px;
}

.navbar-expand-md .navbar-collapse {
  justify-content: flex-end !important;
}

.bg-navigation {
  background: #f3f4f6;
}

div.container {
  max-width: 1170px !important;
  padding: 0 15px;
}

.navbar .navbar-nav .nav-item {
  margin-left: 22px;
}

.navbar .navbar-nav .nav-item a {
  text-decoration: none;
  padding: 0;
}

.navbar .navbar-nav .nav-item button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: #070e32;
  letter-spacing: 0.03em;
  font-weight: 600;
  font-family: "SF Pro Display";
  line-height: 19px;
}

.navbar .navbar-nav .nav-item button:hover {
  color: #1170c9;
}

.navbar .navbar-nav .nav-item a .navbar-text {
  color: #070e32;
  letter-spacing: 0.03em;
  font-weight: 600;
  font-family: "SF Pro Display";
  line-height: 19px;
  padding: 0;
}

.navbar .navbar-nav .nav-item a.active .navbar-text,
.navbar .navbar-nav .nav-item a .navbar-text:hover {
  color: #1170c9;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link:hover {
  color: #1170c9;
}

/******************************************* Home ********************************/
.home-slider {
  height: 649px;
  background: #f3f4f6;
}

.home-slider .slick-arrow {
  width: 48px;
  height: 48px;
  display: inline-block;
  border-radius: 50%;
  background: #ffffff url(./images/left-arrow.png) no-repeat center center;
  line-height: 48px;
  text-align: center;
  z-index: 1;
  margin-top: -40px;
}

.home-slider .slick-arrow:hover {
  background: #1170c9 url(./images/left-arrow.png) no-repeat center center;
}

.home-slider .slick-arrow:before {
  display: none;
}

.home-slider .slick-prev {
  left: 40px;
}

.home-slider .slick-next {
  right: 40px;
  -webkit-transform: translate(0, -50%) rotate(180deg);
  -ms-transform: translate(0, -50%) rotate(180deg);
  transform: translate(0, -50%) rotate(180deg);
}

.identify-people {
  padding-top: 0;
  height: 649px;
}

.identify-people h1 {
  font-size: 36px !important;
  font-weight: 600 !important;
}

.freepik-image {
  padding-left: 0;
}

.identify-people-text-block {
  padding-top: 0;
}

.identify-people-text-block h1 {
  margin-bottom: 20px;
}

.we-digitize {
  max-width: 550px;
}

.we-digitize p {
  font-size: 18px;
  line-height: 27px;
  color: #111635;
  font-weight: 300;
  letter-spacing: 0.02em;
  margin-bottom: 25px;
}

.identify-people-text-block button.join-now-btn {
  width: 130px;
  height: 44px;
  background: #1170c9;
  border: 1px solid #1170c9;
  border-radius: 3px;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: normal;
  margin-top: 3px;
  margin-bottom: 20px;
}

.identify-people-text-block button.join-now-btn:hover {
  background: #000;
  border-color: #000;
}

.benefits-section {
  background-color: #e5e5e5;
}

/************************************** About **************************/

.disclaimer-block h2 {
  font-size: 36px;
  font-weight: 600;
  line-height: 43px;
  letter-spacing: 0.02em;
  color: #070e32;
  margin: 0;
}

.header {
  height: auto;
  background-color: #f3f4f6;
  text-align: center;
}

ul {
  margin-bottom: 0 !important;
}

.about-content-p {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.02em;
  font-weight: 300;
  color: #111635;
}

.about-header-box {
  padding-top: 120px;
  max-width: 749px;
  margin: 0 auto;
  padding-bottom: 182px;
}

.kenneth-tang-box {
  max-width: 859px;
  /* max-height: 289px; */
  margin: 0 auto;
  margin-top: -140px;
  background-color: white;
  box-shadow: 0px 13px 94px rgba(124, 139, 160, 0.14);
  border-radius: 20px;
  padding: 36px 51px 36px 56px;
}

.about-container-box {
  width: 860px;
  box-shadow: 0px 13px 94px rgba(124, 139, 160, 0.14);
  border-radius: 20px;
}

.kenneth-tang-laerke {
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  color: #000;
  margin-bottom: 4px;
  font-family: "Roboto", sans-serif;
}

.founder-and-ceo {
  font-size: 14px;
  line-height: 21px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #000000;
}

.the-company-block {
  margin-top: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #111635;
  letter-spacing: 0px;
  max-width: 485px;
}

.the-company-block .bold-500 {
  font-family: "Roboto", sans-serif;
}

.bold-500 {
  font-weight: 600;
}

.disclaimer-block {
  margin-top: 60px;
}

.disclaimer-box {
  max-width: 860px;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0px 13px 94px rgba(124, 139, 160, 0.14);
  border-radius: 20px;
  padding: 36px 30px;
  margin-top: 32px;
}

.void-stamp-image {
  padding: 40px 0px;
}

.void-id {
  font-size: 24px;
  line-height: 29px;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: #070e32;
}

.void-id-content {
  margin-top: 8px;
  max-width: 547px;
}

.void-id-content ul {
  padding-left: 24px;
}

.disclaimer-box img {
  margin-right: 4px;
}

.void-id-content ul li {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #111635;
}

.disclosure-list {
  font-size: 16px;
}

.mb-108 {
  margin-bottom: 108px;
}

/********************************************** Footer ***********************************/

.footer-bg {
  background-color: #f9f9f9;
  padding: 78px 0px 94px 0px;
}

.install-mobile-app {
  font-size: 14px;
  letter-spacing: 0.1em;
  line-height: 14px;
  color: #111635;
  margin-bottom: 8px;
  display: block;
  font-weight: 400;
}

.install-mobile-app::after {
  content: "";
  background-color: #000000;
  width: 84px;
  height: 3px;
  display: inline-block;
  vertical-align: top;
  margin-top: 4px;
  margin-left: 11px;
}

.download-one-id {
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.02em;
  margin-bottom: 8px;
  color: #070e32;
  display: block;
}

.download-one-id em {
  font-style: normal;
  display: inline-block;
  line-height: 24px;
  vertical-align: 2px;
}

.bold-700 {
  font-weight: 700;
}

.download-btn-block {
  display: inline-block;
  margin-left: 16px;
}

.global-mobile {
  font-size: 14px;
  color: #111635;
  line-height: 20px;
  display: block;
}

.download-btn-block .download-btn {
  background: none;
  color: black;
  border-radius: 40px;
  width: 224px;
  height: 44px;
  line-height: 42px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  border: 1px solid #000;
  text-decoration: none;
}

.download-btn-block .download-btn:hover {
  background-color: #000;
  color: #fff;
}

.copy-right-block {
  padding: 18px 0px 28px 0px;
}

.all-right {
  font-size: 14px;
  line-height: 24px;
  color: #070e32;
}

.privacy-policy {
  float: right;
}

.terms-privacy {
  color: #070e32;
  margin-left: 17px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
}

.copy-right-center {
  align-items: center !important;
  justify-content: space-between;
}

.apple-icon {
  margin-right: 8px;
  font-size: 18px;
}

.home-benefit {
  width: 100%;
  background-color: #fff;
  padding: 110px 0;
}

.section-title {
  width: 100%;
  text-align: center;
}

.section-title span {
  font-size: 14px;
  line-height: 14px;
  color: #6b7280;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  position: relative;
  padding: 0 16px;
  display: inline-block;
  margin-bottom: 10px;
}

.section-title span:before,
.section-title span:after {
  content: "";
  width: 40px;
  height: 1px;
  display: inline-block;
  background-color: #d1d5db;
  position: absolute;
  top: 7px;
}

.section-title span:before {
  right: 100%;
}

.section-title span:after {
  left: 100%;
}

.section-title h2 {
  color: #111827;
  font-size: 36px;
  line-height: 43px;
  font-weight: 100;
  letter-spacing: 0.02em;
  margin: 0;
}

.section-title h2 strong {
  font-weight: bold;
}

.benefit-area {
  width: 100%;
  margin-top: 90px;
  padding: 0 15px;
}

.benefit-area .row {
  align-items: center;
}

.benefit-box {
  background: #ffffff;
  box-shadow: 0px 13px 94px rgba(124, 139, 160, 0.14);
  border-radius: 20px;
  padding: 19px 25px;
  position: relative;
}

.benefit-box+.benefit-box {
  margin-top: 66px;
  margin-bottom: 55px;
}

.benefit-box img {
  margin-bottom: 8px;
}

.benefit-box.box-1:before {
  content: url(../src/images/box1-line.png);
  position: absolute;
  top: 50%;
  left: 100%;
  margin: 2px 0 0 8px;
}

.benefit-box.box-1:after,
.benefit-box.box-2:after {
  content: url(../src/images/box1-circle.png);
  position: absolute;
  top: 50%;
  left: 100%;
  margin: 28px 0 0 237px;
}

.benefit-box.box-2:before {
  content: url(../src/images/box2-line.png);
  position: absolute;
  top: 50%;
  left: 100%;
  margin: 2px 0 0 8px;
}

.benefit-box.box-2:after {
  margin: 45px 0 0 155px;
}

.benefit-box.box-3:before {
  content: url(../src/images/box3-line.png);
  position: absolute;
  top: 50%;
  right: 100%;
  margin: 2px 6px 0 0;
}

.benefit-box.box-3:after {
  content: url(../src/images/box1-circle.png);
  position: absolute;
  top: 100%;
  right: 100%;
  margin: 57px 128px 0 0;
}

.benefit-box.box-2 img {
  margin-bottom: 16px;
}

.benefit-box.box-3 img {
  margin-bottom: 21px;
}

.benefit-box h4 {
  margin-bottom: 8px;
  font-size: 24px;
  line-height: 29px;
  font-weight: bold;
  color: #070e32;
}

.benefit-box p {
  margin: 0;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #000000;
}

.benefit-img {
  width: 100%;
  text-align: center;
}

.home-solution {
  width: 100%;
  background-color: #fff;
  padding: 40px 0 119px;
}

.solution-area {
  width: 100%;
  margin-top: 85px;
}

.solution-box {
  width: 100%;
  text-align: center;
}

.solution-image {
  width: 100%;
  margin-bottom: 23px;
}

.solution-content h3 {
  color: #070e32;
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  letter-spacing: 0.02em;
  margin: 0 0 10px;
}

.solution-content p {
  max-width: 375px;
  margin: 0 auto;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #111635;
}

.solution-content a {
  width: 165px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #1170c9;
  box-sizing: border-box;
  border-radius: 3px;
  font-weight: 600;
  font-size: 14px;
  line-height: 42px;
  text-transform: uppercase;
  color: #1170c9;
  display: inline-block;
  text-decoration: none;
  margin-top: 24px;
}

.solution-content a:hover {
  background-color: #1170c9;
  color: #fff;
}

.home-vision {
  width: 100%;
  background: #f3f4f6 url(../src/images/map.png) no-repeat top right;
  padding: 198px 0 200px;
}

.vision-content {
  max-width: 544px;
}

.vision-content h6 {
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.1em;
  color: #111635;
  margin: 0 0 24px;
  display: inline-block;
}

.vision-content h6:after {
  content: "";
  width: 84px;
  height: 3px;
  display: inline-block;
  background: #000000;
  vertical-align: top;
  margin: 4px 0 0 16px;
}

.vision-content h2 {
  font-weight: 300;
  font-size: 48px;
  line-height: 62px;
  letter-spacing: 0.02em;
  color: #111827;
  margin: 0;
}

.vision-content h2 em {
  font-style: normal;
  display: inline-block;
  line-height: 42px;
  vertical-align: 5px;
}

.vision-content h2 span {
  color: #1170c9;
  font-weight: bold;
}

.solution-banner {
  width: 100%;
  background: #f3f4f6;
  padding: 155px 0 216px;
  text-align: center;
}

.solution-banner h1 {
  font-weight: 100 !important;
  font-size: 48px !important;
  line-height: 57px !important;
  letter-spacing: 0.02em !important;
  color: #070e32;
  margin: 0;
}

.solution-banner h1 span {
  font-weight: bold !important;
  position: relative;
}

.solution-banner h1 span:after {
  content: url(../src/images/title-border.png);
  position: absolute;
  bottom: -11px;
  left: 0;
  line-height: 14px;
}

.business-plan {
  width: 100%;
  margin-top: -100px;
}

.service-box {
  background-color: #f2f3f5;
  padding-bottom: 51px;
}

.border-radius-bottom {
  border-radius: 0 0 20px 20px !important;
  box-shadow: none !important;
}

.border-radius-top {
  border-radius: 20px 20px 0 0 !important;
  box-shadow: none !important;
}

.padding-top0 {
  padding-top: 0 !important;
}

.plan-row {
  background: #ffffff;
  box-shadow: 0px 13px 94px rgba(124, 139, 160, 0.14);
  border-radius: 20px;
  padding: 51px 41px 51px 36px;
  display: flex;
  max-width: 1041px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
}

.plan-row h4 {
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #070e32;
  margin: 0 0 16px;
}

.plan-row p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #111635;
  font-family: "Roboto", sans-serif;
  max-width: 605px;
  margin: 0;
}

.plan-row a,
.plan-row button.btn,
.plan-btn button.btn {
  width: 167.35px;
  height: 44px;
  background: #1170c9;
  border-radius: 3px;
  text-align: center;
  line-height: 44px;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
  display: inline-block;
  text-decoration: none;
  padding: 0;
  border: none;
  vertical-align: top;
  box-shadow: none !important;
}

.plan-row a:hover,
.plan-row button.btn:hover,
.plan-btn button.btn:hover {
  background: #000;
  color: #ffffff;
}

.business-problem {
  width: 100%;
  padding: 117px 0 70px;
}

.business-problem .section-title span,
.business-opportunity .section-title span {
  margin-bottom: 20px;
}

.problem-area {
  width: 100%;
  margin-top: 78px;
}

.problem-box {
  width: 100%;
  text-align: center;
  max-width: 355px;
  margin: 0 auto;
}

.problem-image,
.bus-image {
  min-height: 96px;
  margin-bottom: 34px;
}

.problem-box h4 {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.02em;
  color: #070e32;
  margin: 0 0 18px;
}

.problem-box p {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #111635;
  margin: 0;
}

.business-opportunity {
  width: 100%;
  padding: 70px 0 70px;
}

.business-value {
  width: 100%;
  padding: 71px 0 114px;
}

.value-area {
  width: 100%;
  margin-top: 48px;
}

.value-box {
  width: 100%;
  max-width: 757px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 13px 94px rgba(124, 139, 160, 0.14);
  border-radius: 20px;
  padding: 60px 55px 77px 56px;
}

.value-box ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.value-box ul li {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #111635;
  background: url(../src/images/check-arrow.png) no-repeat top 5px left;
  padding-left: 52px;
}

.value-box ul li+li {
  margin-top: 36px;
}

.value-box ul li:last-child {
  margin-top: 60px;
}

.individual-value .value-box ul li:last-child {
  margin-top: 36px;
}

.individual-banner {
  width: 100%;
  background: #f3f4f6;
  padding: 155px 0 216px;
  text-align: center;
}

.individual-banner h1 {
  font-weight: 100 !important;
  font-size: 48px !important;
  line-height: 57px !important;
  letter-spacing: 0.02em !important;
  color: #070e32;
  margin: 0;
}

.individual-banner h1 span {
  font-weight: bold !important;
  position: relative;
}

.individual-banner h1 span:after {
  content: url(../src/images/individual-border.png);
  position: absolute;
  bottom: -11px;
  left: 0;
  line-height: 14px;
}

.personal-plan {
  width: 100%;
  margin-top: -72px;
}

.personal-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.personal-row h4 {
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin: 0;
}

.free-box {
  width: 50%;
  height: 140px;
  margin-right: 30px;
  background: #1170c9;
  box-shadow: 0px 13px 94px rgba(124, 139, 160, 0.14);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px 0 30px;
  margin: 0 auto;
}

.free-box a,
.free-box button {
  width: 165px;
  height: 44px;
  background: #ffffff;
  border-radius: 3px;
  text-align: center;
  line-height: 44px;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: #1170c9;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  border: none;
  outline: none !important;
}

.free-box a:hover,
.free-box button:hover {
  background: #000;
  color: #fff;
}

.pro-box {
  width: 50%;
  height: 140px;
  margin-left: 30px;
  background: #000000;
  box-shadow: 0px 13px 94px rgba(124, 139, 160, 0.14);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px 0 30px;
}

.pro-box a,
.pro-box button {
  width: 165px;
  height: 44px;
  background: #ffffff;
  border-radius: 3px;
  line-height: 44px;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: #000000;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  border: none;
  outline: none !important;
}

.pro-box a:hover,
.pro-box button:hover {
  background: #1170c9;
  color: #fff;
}

.pro-box h6 {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #34d399;
  font-family: "Roboto", sans-serif;
  margin: 5px 0 0;
}

.pro-box h6 span {
  /* text-decoration-line: line-through; */
  font-family: "Roboto", sans-serif;
  color: #ffffff;
}

.pro-box p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  margin: 0;
}

.individual-opportunity {
  padding-top: 0;
}

.individual-value {
  padding-top: 50px;
  padding-bottom: 108px;
}

.individual-problem {
  padding-top: 146px;
  padding-bottom: 68px;
}

.home .footer-bg {
  background-color: #f3f4f6;
}

.about-header-box h1 {
  margin-bottom: 20px;
  font-weight: 600 !important;
}

.about-header-box h1 em {
  font-style: normal;
  vertical-align: 5px;
  display: inline-block;
  line-height: 45px;
}

.about-header-box h1 span {
  font-weight: 700 !important;
}

.kenneth-tang-box img {
  margin-right: 6px;
  max-width: 217px;
  width: 100%;
  max-height: 217px;
  height: 100%;
}

/***** Drop-Down *****/
.drop-down {
  color: #fff;
  background: transparent;
  display: block;
  float: left;
  padding: 0;
  margin-left: 22px;
  position: relative;
  text-decoration: none;
  transition-duration: 0.5s;
}

.drop-down a {
  color: #070e32;
  letter-spacing: 0.03em;
  font-weight: 600;
  font-family: "SF Pro Display";
  line-height: 19px;
  padding: 0;
  text-decoration: none;
}

.drop-down:hover {
  background: transparent;
  cursor: pointer;
}

ul .drop-down ul {
  background: transparent;
  color: #1170c9;
  visibility: hidden;
  opacity: 0;
  min-width: 5rem;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 0;
  z-index: 999;
  display: none;
}

ul .drop-down:hover>ul,
ul .drop-down ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
  background: #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
  padding: 10px 20px;
  border-radius: 5px;
  text-align: start;
}

.nav-link.design-manual {
  color: #070e32 !important;
}

.nav-link.design-manual:hover {
  color: #0060b9 !important;
}

ul .drop-down ul li {
  clear: both;
  width: 100%;
  height: 3vh;
  line-height: 1;
  list-style: none;
}

/* <----New-----> */
.social_page {
  padding: 120px 0;
  min-height: 500px;
}

.social_logo {
  text-align: center;
  font-weight: 500;
  color: #6b7280;
}

ul .drop-down ul li a {
  padding: 0 !important;
}

ul .drop-down ul li a:hover {
  color: #0060b9;
}

.section_heading {
  margin-bottom: 80px;
}

.logobox h3 {
  font-weight: 800;
  color: #070e32;
  line-height: 2;
  font-size: 24px;
}

.logobox .sociamedia_img {
  border-radius: 15px;
  width: 100%;
  max-width: 330px;
  margin-bottom: 20px;
}

.imgdownload {
  display: flex;
  text-decoration: none;

}

.downloaicon {
  width: 30px;
  height: 30px;
  margin: 6px 0;
}

.imgdownload p {
  margin: 0 10px;
}

.pngone {
  font-size: 20px;
  font-weight: 700;
  color: #1170c9;
}

.sizeone {
  font-size: 15px;
  font-weight: 600;
  color: #111635c7;
}

/***** End Drop-Down *****/
.iframe {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

*>body>iframe::-webkit-scrollbar {
  display: none !important;
  width: 1px;
}

/* ::-webkit-scrollbar{
  display: none;
} */
/* .resourced{
  height: 8164.75px;
} */
.resources::-webkit-scrollbar {
  display: none !important;
}

.iframe iframe {
  width: 100%;
  height: 100vh;
  display: block;
}

.disclosure-block ul {
  padding-left: 24px;
}

.mb-100 {
  margin-bottom: 100px;
}

#iframe::-webkit-scrollbar {
  display: none;
}

.identify-people .row {
  align-items: center;
  height: 100%;
}

.identify-people .col-sm-6:first-child {
  order: 2;
}

.slide1 .col-sm-6:first-child {
  align-self: flex-end;
  text-align: right;
}

.slick-slide .slide1 img {
  display: inline-block;
}

.identify-people h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  letter-spacing: 0.02em;
  color: #070e32;
  max-width: 450px;
  margin: 0 0 20px;
}

.freepik-image {
  width: 100%;
  margin-left: 100px;
}

.slick-slide img.img-mob {
  display: none;
}

.disclosure-block {
  margin-top: 66px;
}

.disclosure-block .disclaimer-box {
  padding: 30px 33px 43px;
}

.solution-image {
  height: 248px;
}

.modal-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.02em;
  color: #070e32;
  margin: 0 0 18px;
}

.quote-submit {
  text-align: center;
}

.quote-submit .generate-close {
  border-radius: 8px;
  text-align: center;
  line-height: 44px;
  font-weight: 600;
  height: 44px;
  width: 167px;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgb(12 12 12 / 25%);
  margin-right: 40px;
  color: #666666;
  cursor: pointer;
}

.back-arrow {
  background: none;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 12px;
  left: 12px;
  opacity: 0.7;
}

.back-arrow:hover {
  opacity: 1;
}

.back-arrow img {
  width: 34px;
}

.quote-submit .generate-close:hover {
  background-color: #eee;
  color: #666666;
}

.kyc-account-wrapper .quote-submit {
  margin-top: 30px;
}

.quote-submit input {
  width: 167.35px;
  height: 44px;
  background: #fff !important;
  border-radius: 8px;
  text-align: center;
  line-height: 44px;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: #666666;
  display: inline-block;
  box-shadow: 0px 0px 10px rgb(12 12 12 / 25%);
  text-decoration: none;
  font-size: 16px;
  padding: 0;
  border: none;
  vertical-align: top;
}

.quote-submit input:hover {
  background-color: #eee;
  color: #666666;
}

.kyc-forn-wrapper .quote-submit input {
  background: #49596b !important;
  color: #fff;
}

.kyc-forn-wrapper .quote-submit input:hover {
  background: #222 !important;
}

.quote-input {
  width: 100%;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 18px;
}

.quote-input button {
  border: 1px solid !important;
  border-radius: 10px;
}

.quote-input input {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  display: block;
  background: #f3f4f6 !important;
  border: none;
  height: 42px;
  padding: 0 15px;
  border-radius: 10px;
  outline: none;
  border: 1px solid;
}

.quote-input input:-webkit-autofill,
.quote-input input:-webkit-autofill:hover,
.quote-input input:-webkit-autofill:focus {
  border: 1px solid;
  border-radius: 10px;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #f3f4f6 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.error-msg {
  text-align: left;
  color: #dc1c1c;
  font-size: 14px;
  margin: 0;
  letter-spacing: 0.02em;
}

.modal .close {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  font-size: 28px;
  line-height: 30px;
  text-align: center;
  color: #777;
  background: #f3f4f6;
}

.modal .close:hover {
  background: #000;
  color: #fff;
}

.modal .modal-body {
  padding: 25px;
}

.modal .close span {
  display: inline-block;
  vertical-align: 3px;
}

.join-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.join-wrapper .join-private {
  width: 45%;
  background-color: #1170c9;
  border-radius: 12px;
  color: #fff;
  padding: 25px;
  text-align: center;
  border: none;
  font-size: 20px;
  letter-spacing: 0.02em;
  text-decoration: none;
}

.join-wrapper .join-private:hover {
  background-color: #000;
}

.join-wrapper .join-business {
  width: 45%;
  background-color: #000;
  border-radius: 12px;
  color: #fff;
  padding: 25px;
  text-align: center;
  border: none;
  font-size: 20px;
  letter-spacing: 0.02em;
  text-decoration: none;
}

.join-wrapper .join-business:hover {
  background-color: #1170c9;
}

.Toastify__toast-body {
  font-size: 20px;
  margin: 0;
  letter-spacing: 0.02em;
}

.Toastify__toast-container {
  width: 480px !important;
  max-width: 94%;
}

.Toastify__toast--success {
  background: #359237 !important;
  padding: 25px;
}

.Toastify__close-button {
  position: absolute;
  top: 5px;
  right: 10px;
}

.loader-box {
  display: inline-block;
  width: 100%;
  text-align: center;
  height: 200px;
  border-radius: 3px;
  font-size: 30px;
  padding: 1em;
  position: relative;
  margin-bottom: 0.25em;
  vertical-align: top;
  transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
}

.loader-box.hide {
  display: none;
}

.triple-spinner {
  display: block;
  position: relative;
  width: 125px;
  height: 125px;
  margin: 0 auto;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top: 4px solid #0060b9;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.partner-ac-footer {
  max-width: 213.5px;
  width: 100%;
  margin: 0 auto;
}

.triple-spinner::before,
.triple-spinner::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: 4px solid transparent;
}

.triple-spinner::before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-top-color: #5eabf5;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3.5s linear infinite;
}

.triple-spinner::after {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-top-color: #3888ff;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.75s linear infinite;
}

.form-wrapper.hide {
  display: none;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.support-wrapper {
  width: 100%;
  padding: 40px 0;
}

.support-wrapper .form-wrapper {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.support-wrapper .quote-input,
.support-wrapper .quote-input input {
  max-width: 100%;
}

.quote-input textarea {
  width: 100%;
  height: 100px;
  margin: 0 auto;
  display: block;
  background: #f3f4f6;
  border: none;
  padding: 10px 15px;
  border-radius: 3px;
  outline: none;
}

.support-title {
  text-align: center;
  margin-bottom: 30px;
}

.support-title h1 {
  font-weight: 100 !important;
}

.support-title h1 strong {
  font-weight: bold !important;
}

.download-qrcode {
  width: 100%;
  text-align: center;
}

.download-qrcode img {
  display: block;
  margin: 0 auto;
}

.download-qrcode span {
  width: 100%;
  display: block;
  text-align: center;
  margin: 10px 0;
  font-weight: bold;
  color: #7c8998;
  font-size: 20px;
}

.busi-wrapper ul {
  text-align: left;
  display: inline-block;
  margin-top: 30px;
}

.busi-wrapper ul li {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #111635;
  font-family: "Roboto", sans-serif;
  margin-bottom: 5px;
}

.solution-banner.busi-wrapper {
  padding-bottom: 160px;
}

.bus-banner-content {
  width: 100%;
  margin-top: 50px;
}

.bus-item h4 {
  font-weight: bold;
  font-size: 30px;
  line-height: 33px;
  letter-spacing: 0.02em;
  color: #070e32;
  margin: 0 0 18px;
}

.bus-item p {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #111635;
  margin: 0;
}

.busi-wrapper .section-title span {
  margin-bottom: 20px;
}

/*===============    MEDIA QUERY     ================*/

@media only screen and (max-width: 1399px) {
  .freepik-image {
    margin-left: 0;
  }

  .home-slider .slick-prev {
    left: 20px;
  }

  .home-slider .slick-next {
    right: 20px;
  }
}

@media only screen and (max-width: 1349px) {
  .home-slider .slick-prev {
    left: 10px;
  }

  .home-slider .slick-next {
    right: 10px;
  }
}

@media only screen and (max-width: 1279px) {
  .home-slider .slick-arrow {
    top: 100%;
  }

  .home-slider .slick-prev {
    left: 50%;
    margin-left: -55px;
  }

  .home-slider .slick-next {
    right: 50%;
    margin-right: -55px;
  }
}

@media screen and (max-width: 1199px) and (min-width: 1023px) {
  .benefit-area {
    width: 1140px;
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 18% 57%;
    -ms-transform-origin: 18% 57%;
    transform-origin: 18% 57%;
    margin-top: 0;
  }

  .home-benefit {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 1022px) and (min-width: 768px) {
  .bus-item h4 {
    font-size: 20px;
  }

  .benefit-area {
    width: 1140px;
    -webkit-transform: scale(0.65);
    -ms-transform: scale(0.65);
    transform: scale(0.65);
    -webkit-transform-origin: -4% 57%;
    -ms-transform-origin: -4% 57%;
    transform-origin: -4% 57%;
    margin-top: -25px;
  }

  .home-benefit {
    padding-bottom: 0px;
  }

  .download-btn-block {
    display: block;
    margin: 0;
  }

  .download-btn-block+.download-btn-block {
    margin-top: 8px;
  }

  .kenneth-tang-box .row,
  .disclaimer-box .row {
    align-items: center;
  }

  .kenneth-tang-box .col-auto:last-child,
  .disclaimer-box .col-auto:last-child {
    flex: 1 0;
    width: 100%;
  }

  .identify-people h2,
  .identify-people h1 {
    font-size: 30px;
  }

  .home-slider .slick-arrow {
    width: 40px;
    height: 40px;
  }

  .home-slider .slick-prev {
    margin-left: -45px !important;
  }

  .home-slider .slick-next {
    margin-right: -45px !important;
  }

  .problem-box h4 {
    font-size: 20px;
    line-height: 24px;
    min-height: 48px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .plan-row p {
    max-width: 370px;
  }

  .free-box,
  .pro-box {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 160px;
    padding-bottom: 10px;
  }

  .free-box h4 {
    width: 100%;
    text-align: center;
  }

  .pro-content,
  .pro-btn {
    width: 100%;
    text-align: center;
  }

  .individual-banner {
    padding-bottom: 159px;
  }

  .individual-banner h1 {
    max-width: 750px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1022px) and (min-width: 991px) {
  .benefit-area {
    width: 1140px;
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 2% 57%;
    -ms-transform-origin: 2% 57%;
    transform-origin: 2% 57%;
    margin-top: 15px;
  }
}

@media screen and (max-width: 1199px) and (min-width: 768px) {
  .home-slider .slick-arrow {
    top: 100%;
  }

  .home-slider .slick-prev {
    left: 50%;
    margin-left: -55px;
  }

  .home-slider .slick-next {
    right: 50%;
    margin-right: -55px;
  }

  div.container {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .bus-banner-content .col-4 {
    width: 100%;
    flex: 0 0 100%;
  }

  .social_page {
    padding: 80px 0;
  }

  .logobox .sociamedia_img {
    max-width: 100%;
  }

  .bus-item {
    margin-bottom: 50px;
  }

  .bus-image {
    margin-bottom: 25px;
  }

  .busi-wrapper ul li {
    font-size: 14px;
  }

  div.container {
    max-width: 414px !important;
  }

  .identify-people .col-sm-6:first-child {
    order: 0;
  }

  .identify-people .col-sm-6 {
    width: 100%;
  }

  .benefit-area .col-4 {
    flex: 0 0 auto;
    width: 100%;
  }

  .identify-people-text-block {
    text-align: center;
    max-width: 362px;
    margin: 0 auto;
  }

  .identify-people-text-block h1 {
    /* font-size: 36px !important; */
    line-height: 43px !important;
    margin-bottom: 14px;
  }

  .identify-people h2 {
    font-size: 28px;
    line-height: 34px;
  }

  .navbar-light .navbar-toggler {
    background: url(../src/images/menu-icon.png) no-repeat center center;
    border: none;
    width: 48px;
    height: 48px;
    padding: 0;
    outline: none;
    box-shadow: none !important;
  }

  .menuOpen .navbar-light .navbar-toggler {
    background: url(../src/images/menu-close.png) no-repeat center center;
  }

  .navbar-light .navbar-toggler-icon {
    background: none !important;
  }

  header nav.navbar {
    padding-top: 27px;
  }

  .navbar-collapse {
    background: #ffffff;
    box-shadow: 0px 13px 94px rgba(124, 139, 160, 0.14);
    border-radius: 20px;
    text-align: center;
    margin-top: 6px;
    position: absolute;
    top: 100%;
    left: 25px;
    right: 25px;
    z-index: 99;
  }

  .navbar .navbar-nav .nav-item {
    margin: 17px 0;
  }

  .navbar .navbar-nav .nav-item:first-child {
    margin-top: 52px;
  }

  .navbar .navbar-nav .nav-item:last-child {
    margin-bottom: 57px;
  }

  .navbar .navbar-nav .nav-item a .navbar-text {
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #070e32;
  }

  .slick-slide img.img-desk {
    display: none;
  }

  .slick-slide img.img-mob {
    display: block;
  }

  .home-slider {
    padding: 53px 0;
  }

  .home-slider,
  .identify-people {
    height: auto;
  }

  .slick-slide .slide1 img.img-mob {
    margin: 0 auto;
    margin-right: 0;
  }

  .freepik-image img {
    margin-bottom: 30px !important;
  }

  .identify-people-text-block button.join-now-btn {
    margin-top: 5px;
    margin-bottom: 0;
  }

  .home-slider .slick-arrow {
    top: 100%;
    width: 36px;
    height: 36px;
    margin-top: 27px;
    background-size: 40% !important;
  }

  .home-slider .slick-prev {
    left: 50%;
    margin-left: -46px;
  }

  .home-slider .slick-next {
    right: 50%;
    margin-right: -46px;
  }

  .benefit-area {
    position: relative;
    padding-bottom: 80px;
  }

  .benefit-box {
    position: absolute;
    padding: 18px 20px;
  }

  .benefit-box p {
    display: none;
  }

  .benefit-box img,
  .benefit-box.box-3 img {
    display: inline-block;
    margin: 0 8px 0 0 !important;
    height: 24px;
    vertical-align: middle;
  }

  .benefit-box h4 {
    display: inline-block;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
    vertical-align: middle;
  }

  .benefit-box.box-1 {
    top: 50px;
    left: 12px;
  }

  .benefit-box.box-1:before {
    content: url(../src/images/box1-line-mob.png);
    margin-left: 0;
  }

  .benefit-box.box-1:after {
    margin: 55px 0 0 32px;
  }

  .benefit-box.box-2 {
    right: 10px;
    top: 150px;
  }

  .benefit-box.box-2:before {
    content: url(../src/images/box2-line-mob.png);
    left: 75px;
    top: 100%;
    margin: 0;
  }

  .benefit-box.box-2:after {
    margin: 57px 0 0 0px;
    left: 65px;
    top: 100%;
  }

  .benefit-box.box-3 {
    left: 10px;
    bottom: 0;
  }

  .benefit-box.box-3:before {
    content: url(../src/images/box3-line-mob.png);
    top: inherit;
    bottom: 36px;
    right: 55px;
    margin: 0;
  }

  .benefit-box.box-3:after {
    top: inherit;
    bottom: 100%;
    right: 47px;
    margin: 0px 0px 35px 3px;
  }

  .home-benefit {
    padding: 75px 0;
  }

  .benefit-area {
    margin-top: 48px;
  }

  .solution-area .col-6 {
    flex: 0 0 auto;
    width: 100%;
  }

  .solution-area {
    margin-top: 64px;
  }

  .solution-box {
    margin-bottom: 100px;
  }

  .home-solution {
    padding-bottom: 13px;
  }

  .vision-content {
    text-align: center;
    max-width: 335px;
    margin: 0 auto;
  }

  .vision-content h6,
  .install-mobile-app {
    margin-bottom: 10px;
    color: #6b7280;
    position: relative;
    padding: 0 16px;
    text-transform: uppercase;
    display: inline-block;
  }

  .install-mobile-app {
    margin-bottom: 15px;
  }

  .vision-content h6:before,
  .vision-content h6:after,
  .install-mobile-app:before,
  .install-mobile-app:after {
    content: "";
    width: 40px;
    height: 1px;
    display: inline-block;
    background-color: #d1d5db;
    position: absolute;
    top: 7px;
    margin: 0;
  }

  .vision-content h6:before,
  .install-mobile-app:before {
    right: 100%;
  }

  .vision-content h6:after,
  .install-mobile-app:after {
    left: 100%;
  }

  .vision-content h2 {
    font-size: 36px;
    line-height: 47px;
    letter-spacing: 0.02em;
  }

  .home-vision {
    padding: 120px 0 123px;
    background-position: top center;
  }

  .footer-bg {
    text-align: center;
  }

  .footer-bg .col-auto {
    width: 100%;
  }

  .download-btn-block {
    margin: 8px 0 0;
  }

  .global-mobile {
    margin-bottom: 20px;
  }

  .footer-bg {
    padding: 75px 0 76px 0;
  }

  .all-right {
    text-align: center;
  }

  .privacy-policy {
    width: 100%;
    text-align: center;
  }

  .all-right {
    margin-bottom: 15px;
    line-height: 17px;
  }

  .copy-right-block {
    padding: 29px 0 27px;
  }

  .navbar-light .navbar-brand img {
    width: 70px;
  }

  .navbar-brand {
    margin-left: 15px;
  }

  .solution-banner h1 {
    max-width: 340px;
    margin: 0 auto;
  }

  .solution-banner {
    padding: 87px 0 251px;
  }

  .plan-row {
    flex-wrap: wrap;
  }

  .plan-content {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }

  .plan-btn {
    width: 100%;
    text-align: center;
  }

  .plan-row h4 {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 20px;
  }

  .business-plan .container {
    padding: 0 27px;
  }

  .plan-row {
    padding: 30px 30px 34px;
  }

  .business-plan {
    margin-top: -144px;
  }

  .problem-area .col-4 {
    width: 100%;
  }

  .problem-box {
    max-width: 355px;
    margin: 0 auto;
    margin-bottom: 60px;
  }

  .business-problem {
    padding: 75px 0 0;
  }

  .problem-area {
    margin-top: 60px;
  }

  .business-opportunity {
    padding: 35px 0 0px;
  }

  .business-opportunity .section-title h2 {
    max-width: 250px;
    margin: 0 auto;
  }

  .business-value {
    padding: 40px 0 80px;
  }

  .value-area {
    margin-top: 36px;
  }

  .value-box {
    padding: 34px 26px 48px 27px;
    max-width: 360px;
  }

  .value-box ul li {
    padding-left: 41px;
  }

  .value-box ul li+li,
  .value-box ul li:last-child {
    margin-top: 30px;
  }

  .footer-bg {
    background: #f3f4f6;
  }

  .personal-row {
    flex-wrap: wrap;
  }

  .free-box {
    width: 100%;
    max-width: 340px;
    margin: 0 auto 24px;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 25px;
  }

  .pro-box {
    width: 100%;
    height: 200px;
    max-width: 340px;
    margin: 0 auto;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 25px;
  }

  .free-box h4 {
    width: 100%;
    align-self: flex-end;
    margin-bottom: 12px;
  }

  .free-box a,
  .free-box button {
    align-self: flex-start;
  }

  .pro-content {
    width: 100%;
    align-self: flex-end;
  }

  .pro-btn {
    width: 100%;
    align-self: flex-start;
  }

  .pro-box h6 {
    margin-top: 0;
  }

  .individual-banner h1 {
    max-width: 320px;
    margin: 0 auto;
  }

  .individual-banner {
    padding: 87px 0 150px;
  }

  .pro-box p {
    font-size: 14px;
  }

  .individual-value .value-box ul li:last-child {
    margin-top: 30px;
  }

  .individual-value .value-box {
    padding-bottom: 43px;
  }

  .business-value.individual-value {
    padding-bottom: 100px;
  }

  .terms-privacy {
    margin: 0 9px;
  }

  .about-header-box {
    padding-top: 125px;
    padding-bottom: 336px;
  }

  .about-header-box .about-content-p {
    max-width: 370px;
    margin: 0 auto;
  }

  .kenneth-tang-box {
    margin-top: -281px;
    max-width: 360px;
    text-align: center;
    padding: 47px 51px 46px;
  }

  .kenneth-tang-box .row {
    justify-content: center;
  }

  .kenneth-tang-box img {
    margin: 0 0 20px;
    max-width: 186px;
  }

  .disclaimer-box .col-auto {
    width: 100%;
  }

  .disclaimer-box img {
    display: block;
    margin: 0 auto;
    padding: 0 0 16px;
  }

  .void-id {
    text-align: center;
  }

  .disclaimer-box {
    padding: 49px 20px 36px 29px;
    max-width: 360px;
  }

  .disclosure-block {
    margin-top: 63px;
    margin-bottom: 76px;
  }

  .disclosure-block .disclaimer-box {
    padding: 22px 20px 60px 20px;
  }

  .disclosure-block ul {
    padding-left: 20px;
  }

  .copy-right-block .col-sm-6 {
    width: 100%;
  }

  .Toastify__toast-container {
    right: 0;
    margin: 0 auto !important;
    margin-top: 25px !important;
  }

  .Toastify__toast-body {
    font-size: 20px;
  }

  .join-wrapper .join-private,
  .join-wrapper .join-business {
    padding: 18px;
  }
}

.price-wrapper {
  width: 100%;
  margin-top: 10px;
}

.price-wrapper .price-row {
  background: #ffffff;
  box-shadow: 0px 13px 94px rgb(124 139 160 / 14%);
  border-radius: 20px;
  padding: 40px 15px 40px 15px;
}

.price-title {
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
}

.price-title.partner-title {
  margin-bottom: 0;
}

.price-title h4 {
  font-weight: bold;
  font-size: 30px;
  line-height: 33px;
  letter-spacing: 0.02em;
  color: #070e32;
  margin: 0 0 18px;
}

.price-title p {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #111635;
  max-width: 550px;
  margin: 0 auto;
}

.price-box-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.price-box-column {
  width: 33.33%;
  flex: 0 0 33.33%;
  padding: 0 15px;
}

.price-box {
  width: 100%;
  background-color: #f3f4f6;
  /* background-color: #fff; */
  padding: 20px 20px 80px;
  height: 100%;
  border-radius: 12px;
  text-align: left;
  position: relative;
}

.features {
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.feature-label {
  text-align: center;
  display: inline-block;
  margin-bottom: 14px;
  font-weight: 700;
  border-bottom: 1px solid #000;
}

/* .price-box.enterprise-box{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
} */

.price-box.enterprise-box .price-footer .price-btn {
  margin-top: 0;
}

.price-footer .price-body {
  min-height: auto;
}

.price-footer .price-body p {
  margin-bottom: 0px;
}

.feature-label.sign-feature {
  font-size: 16px;
}

.price-box.active-plan .feature-label {
  border-bottom: 1px solid #fff;
}

.active-plan {
  background-color: #1170c9;
}

.active-plan {
  color: #fff;
}

.font_bold {
  font-weight: bold !important;
}

.price-btn {
  height: 44px;
  background: #1170c9;
  border-radius: 3px;
  text-align: center;
  line-height: 44px;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
  display: inline-block;
  text-decoration: none;
  padding: 0;
  border: none;
  margin-top: 15px;
  position: absolute;
  bottom: 1%;
  right: 5%;
  left: 5%;
}

.signup-btn {
  width: 100%;
  height: 44px;
  background: #1170c9;
  border-radius: 3px;
  text-align: center;
  line-height: 44px;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
  display: inline-block;
  text-decoration: none;
  padding: 0;
  border: none;
  margin-top: 15px;
}

.active-plan .price-btn {
  background: #fff;
  color: #1170c9;
}

.active-plan .price-btn:hover,
.price-btn:hover {
  background-color: #000;
  color: #fff;
}

.price-body {
  min-height: 360px;
}

.price-body ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

.price-body ul li {
  background: url(../src/images/v_icon.png) no-repeat top 3px left;
  background-size: 18px;
  padding-left: 25px;
  margin-bottom: 10px;
  line-height: 24px;
}

.price-body ul li.no-plan-check {
  background: url(../src/images/cross-arrow.svg) no-repeat top 3px left;
  background-size: 18px;
  padding-left: 25px;
  margin-bottom: 10px;
  line-height: 24px;
}

.active-plan .price-body ul li {
  background: url(../src/images/v_icon_white.png) no-repeat top 3px left;
  background-size: 18px;
  color: #fff;
}

.price-header {
  min-height: 170px;
  text-align: center;
}

.price-header h5 {
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 15px;
  text-align: center;
}

.price-header h3 {
  font-weight: bold;
  font-size: 20px;
}

.price-header h3 em {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
}

.price-header h3 p {
  margin: 0;
  display: inline-block;
  font-size: 20px;
}

.price-header h3 span {
  font-size: 80%;
}

.price-header h6 {
  font-size: 20px;
}

.bus-item .plan-btn {
  margin-top: 15px;
}

.bus-item {
  max-width: 430px;
  margin: 0 auto;
  text-align: center;
}

.bus-item p {
  min-height: 75px;
}

.price-upper-box {
  width: 100%;
  flex: 0 0 100%;
  margin-bottom: 0;
}

.price-box-row {
  margin-bottom: 0px;
}

.solution-banner.busi-wrapper {
  padding-top: 100px;
}

.plan-row {
  flex-wrap: wrap;
  max-width: 100%;
  padding-bottom: 40px;
  padding-top: 40px;
}

.plan-row-box {
  width: 100%;
  display: flex;
  flex: 0 0 100%;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 13px 94px rgb(124 139 160 / 14%);
  border-radius: 20px;
  padding: 51px 41px 51px 36px;
  margin-top: 60px;
}

.plan-row-box .plan-content p:last-child {
  margin: 0;
}

.business-plan .container {
  padding-left: 0;
  padding-right: 0;
}

.plan-row-box h4 {
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #070e32;
  margin: 0 0 16px;
}

.plan-row-wrapper {
  padding: 0 30px 40px;
  background-color: #fff;
  border-radius: 0px 0px 20px 20px;
}

.plan-row-wrapper .plan-row {
  background-color: #f3f4f6;
  padding: 30px !important;
  border-radius: 12px !important;
}

.download-free ul {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.download-free ul li {
  list-style: none;
}

.download-free ul li {
  margin-right: 15px;
}

.box-download {
  display: flex;
  align-items: center;
}

.download-free ul li a {
  width: 50px;
}

.download-free ul li a svg {
  margin: 0;
  width: 24px;
  height: 24px;
}

.quote-input label {
  font-weight: 600;
  color: #333333;
  text-align: left;
  display: block;
  margin: 0 0 4px;
}

.quote-input .error {
  text-align: left;
  color: #dc1c1c;
  font-size: 14px;
  margin: 0;
  display: block;
}

.generate-modal.modal-dialog {
  max-width: 900px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.kyc-account-wrapper h5,
.generate-div h5 {
  color: #49596b;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 10px;
}

.kyc-account-wrapper p,
.generate-div p {
  color: #666666;
  margin-bottom: 8px;
}

.kyc-account-wrapper .quote-input {
  max-width: 100%;
  margin-bottom: 12px;
}

.kyc-account-wrapper .quote-input input {
  max-width: 100%;
  height: 38px;
}

.kyc-forn-wrapper {
  width: 100%;
  margin-bottom: 10px;
}

.kyc-account-wrapper+.generate-div {
  margin-top: 10px;
}

.qr-div {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 15px 0;
  position: relative;
}

.qr-div.disabled:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #666;
  opacity: 0.95;
}

.qr-div img {
  max-height: 270px;
}

.generate-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 5px 0;
}

.modal-header {
  padding: 12px 16px !important;
}

.generate-footer button,
.generate-footer a {
  width: 30%;
  flex: 0 0 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(12, 12, 12, 0.25);
  border-radius: 8px;
  height: 45px;
  color: #666666;
  font-weight: 600;
  text-decoration: none;
}

.generate-footer button {
  width: 24%;
  flex: 0 0 24%;
}

.generate-footer button a {
  width: auto;
  flex: 0 0 auto;
  box-shadow: none;
  background: none;
}

.generate-footer .check-policy label {
  display: inline-block;
  /* width: 24px;
  height: 24px; */
  padding: 0 0 0 32px;
  position: relative;

  margin-right: 8px;
}

.generate-footer .check-policy input {
  display: none;
}

.generate-footer .check-policy input+label:before {
  content: '';
  width: 24px;
  height: 24px;
  display: inline-block;
  border: 2px solid #666;
  border-radius: 50%;
  position: absolute;
  top: 9px;
  left: 0;
  cursor: pointer;
}

.generate-footer .check-policy input:checked+label:before {
  background-color: #666;
}

.generate-footer .check-policy input:checked+label:after {
  content: '';
  width: 8px;
  height: 12px;
  display: inline-block;
  border-bottom: 3px solid #fff;
  position: absolute;
  top: 13px;
  left: 8px;
  border-right: 3px solid #fff;
  transform: rotate(45deg);
}

.generate-footer button:hover,
.generate-footer a:hover {
  background-color: #eee;
  color: #666666;
}

.modal .generate-modal .modal-body {
  padding: 20px 35px;
}

.generate-footer button img,
.generate-footer a img {
  width: 24px;
  height: auto;
  margin-right: 12px;
}

.generate-modal .modal-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #49596b;
  font-weight: bold;
  padding: 0 15px;
  margin: 0 !important;
}

.generate-modal .modal-title img {
  max-width: 70px;
}

/* .modal.show{
  display: flex !important;
  align-items: center;
  justify-content: center;
} */
.modal-dialog {
  min-height: calc(100% - (1.75rem * 2));
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.modal .close {
  position: absolute;
  top: -18px;
  right: -18px;
}

.form-message-body {
  width: 100%;
  text-align: center;
}

.quote-modal .modal-title {
  margin: 0;
}

.form-message-body h4 {
  font-size: 28px;
  font-weight: bold;
  color: #49596b;
  margin: 25px 0 20px;
}

.form-message-body button {
  background-color: #49596b;
  color: #fff;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  font-weight: 600;
  padding: 12px 70px;
  display: inline-block;
}

.form-message-body button:hover {
  background-color: #222;
}

.form-message-body p {
  color: #666666;
  margin-bottom: 25px;
  word-break: break-word;
  white-space: pre-line;
}

.modal .modal-content {
  border-radius: 16px;
}

.form-message-body .scs-img {
  width: 250px;
}

.form-message-body .warn-img {
  width: 120px;
}

.modal-dialog.formmsg-modal {
  max-width: 600px;
}

.formmsg-modal .modal-body {
  padding: 50px 100px;
}

.kyc-forn-wrapper input[type="number"]::-webkit-inner-spin-button,
.kyc-forn-wrapper input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.hide {
  display: none;
}

.account-select h5 {
  color: #49596b;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 15px;
}

.select-company button {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  width: 100%;
  display: block;
  background-color: #fff;
  border-radius: 8px;
  border: 2px solid transparent;
  text-align: center;
  color: #49596b;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
  padding: 12px 20px;
  /* padding: 12px; */
  cursor: pointer;
}

.select-company label {
  width: 100%;
  position: relative;
  cursor: pointer;
  margin-bottom: 20px;
}

.select-company input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.select-company input:checked+button {
  border-color: #49596b;
}

.select-company button:hover {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
}

.select-company button span,
.select-company button div {
  display: inline-block;
  text-align: left;
  min-width: 170px;
}

.displaygird {
  display: inline-grid !important;
}

.select-company button img {
  max-width: 28px;
  margin-right: 8px;
  vertical-align: -8px;
}

.account-footer {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 20px 0 10px;
}

.account-footer button {
  margin: 0 15px;
}

.btn-darken {
  background-color: #49596b;
  color: #fff;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  font-weight: 600;
  padding: 12px 85px;
  display: inline-block;
}

.btn-darken:hover {
  background-color: #222;
}

.btn-dark-border {
  background: none;
  border: 2px solid #49596b;
  color: #49596b;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 70px;
  display: inline-block;
}

.btn-dark-border:hover {
  background: #49596b;
  color: #fff;
}

.qot-row {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.qot-col1 {
  width: 35%;
  flex: 0 0 35%;
}

.qot-col4 {
  width: 24%;
  flex: 0 0 24%;
}

.qot-col2 {
  width: 26%;
  flex: 0 0 26%;
}

.qot-col3 {
  width: 35%;
  flex: 0 0 35%;
}

.qot-col5 {
  width: 49%;
  flex: 0 0 49%;
}

.mr2per {
  margin-right: 2%;
}

.qot-row .quote-input .error {
  font-size: 13px;
  white-space: nowrap;
}

.quote-select button {
  border: none;
  background-color: #f3f4f6;
  padding: 3px 10px;
}

.quote-select button span.ReactFlagsSelect-module_label__27pw9 {
  width: 150px;
  text-align: left;
}

.open-file {
  position: relative;
}

.open-file:before {
  content: "Open file";
  position: absolute;
  top: 35px;
  left: 0;
  background: #fff;
  border: 1px solid #666;
  padding: 2px 5px;
  font-weight: normal;
  display: none;
  font-size: 14px;
}

.open-file:hover:before {
  display: block;
}

.textcenter {
  text-align: center !important;
}

@media screen and (max-height: 700px) {

  .home-slider,
  .identify-people {
    height: 550px;
  }

  .slick-slide .freepik-image img {
    max-height: 470px;
  }
}

@media only screen and (max-width: 1199px) {
  .price-box-column {
    padding: 0 8px;
  }

  .price-header h3 {
    font-size: 20px;
  }

  .business-plan .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .plan-row-box {
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
  }

  .plan-row-box .plan-content {
    width: 100%;
    flex: 0 0 100%;
    margin-bottom: 20px;
  }

  .price-box-column {
    width: 50%;
    flex: 0 0 50%;
    margin-bottom: 30px;
  }

  .price-box-column {
    padding: 0 15px;
  }

  .price-header h3 {
    font-size: 24px;
  }

  .qot-row {
    flex-wrap: wrap;
  }

  .qot-col1,
  .qot-col2,
  .qot-col3 {
    width: 100%;
    flex: 0 0 100%;
  }

  .generate-footer button a {
    margin: 0 !important;
  }

  .modal.show {
    display: block !important;
  }
}

@media only screen and (max-width: 767px) {
  .price-upper-box .col-6 {
    width: 100%;
    flex: 0 0 100%;
  }

  .bus-item {
    margin-bottom: 50px;
  }

  .price-upper-box {
    margin-bottom: 0;
  }

  .price-box-column {
    width: 100%;
    flex: 0 0 100%;
    margin-bottom: 30px;
  }

  .price-header {
    min-height: inherit;
    margin-bottom: 25px;
  }

  .price-body {
    min-height: inherit;
    margin-bottom: 5px;
  }

  .solution-banner.busi-wrapper {
    padding-bottom: 175px;
  }

  .plan-row-box {
    margin-top: 30px;
  }

  .qr-div {
    flex-wrap: wrap;
  }

  .qr-div img {
    max-width: 300px;
    margin-bottom: 25px;
    display: none;
  }

  .generate-footer {
    flex-wrap: wrap;
  }

  .generate-footer button,
  .generate-footer a {
    width: 100%;
    flex: 0 0 100%;
    margin: 10px 0;
  }

  .select-company button {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
  }

  .select-company button img {
    max-width: 26px;
    margin-right: 6px;
  }

  .account-footer {
    flex-wrap: wrap;
    margin-top: 30px;
  }

  .btn-dark-border {
    font-size: 18px;
    padding: 7px 70px;
  }

  .btn-darken {
    font-size: 18px;
    padding: 9px 86px;
  }

  .account-footer .btn-dark-border {
    margin-bottom: 15px;
  }

  .generate-modal.modal-dialog {
    width: 90%;
    margin: 40px auto;
  }

  .generate-modal .modal-title {
    flex-wrap: wrap;
    padding: 0;
    font-size: 22px;
    margin: 0;
  }

  .generate-modal .modal-title img {
    max-width: 50px;
  }

  .kyc-account-wrapper h5,
  .generate-div h5,
  .account-select h5 {
    font-size: 22px;
  }
}

.margin_0auto {
  margin: 0 auto;
}


/* Loader CSS */
.loader-bg {
  width: 100%;
  height: 100%;
  background: #0000009f;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;
}

.loader-bg h3 {
  position: fixed;
  color: #ff5e31;
  font-size: 24px;
}

.loader {
  width: 8em;
  height: 8em;
  font-size: 22px;
  box-sizing: border-box;
  border-top: 0.3em solid #505050;
  border-radius: 50%;
  position: relative;
  animation: rotating 2s ease-in-out infinite;
  --direction: 1;
}

.loader span {
  position: absolute;
  color: #FF5E31;
  width: inherit;
  height: inherit;
  text-align: center;
  line-height: 10em;
  font-family: sans-serif;
  animation: rotating 2s linear infinite;
}

.loader::before,
.loader::after {
  content: '';
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  box-sizing: border-box;
  top: -0.2em;
}

.loader::before {
  border-top: 0.3em solid dodgerblue;
  transform: rotate(120deg);
}

.loader::after {
  border-top: 0.3em solid #ff5e31;
  transform: rotate(240deg);
}

@keyframes rotating {
  50% {
    transform: rotate(calc(180deg));
  }

  100% {
    transform: rotate(calc(360deg));
  }
}

/* Over */

.companylist {
  /* max-width: 430px; */
  width: 100%;
  margin: 0 auto;
}

.companylist .row {
  justify-content: center;
}

.companylist .displaygird p {
  margin-bottom: 0;
  word-break: break-all;
  color: #a5a5a5;

}

.companylist .displaygird .displaygird-text {
  font-size: 24px;
  color: #49596b;
}

.companylist button span,
.companylist button div {
  text-align: left;
  min-width: auto;
  display: flex;
  /* justify-content: space-around; */
  justify-content: space-between;
  align-items: center;
}

/***** identify Modal *****/
.identify button {
  color: #0d6efd;
  text-decoration: underline;
  border: none;
  outline: none;
  background: transparent;
}

.generate-modal.identify-modal .ac-mid-title,
.generate-modal.identify-modal .ac-sub-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}

.generate-modal.identify-modal .kyc-account-wrapper h5 {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  margin-bottom: 15px;
}

.generate-modal.identify-modal .kyc-account-wrapper p {
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  margin-bottom: 15px;
}

.generate-modal.identify-modal .identify-img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto;
  height: 350px;
}

.generate-modal.identify-modal .identify-img img {
  height: 100%;
}

.generate-modal.identify-modal .generate-footer {
  display: flex;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.f30 {
  font-size: 30px !important;
}

.mb0 {
  margin-bottom: 0px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

/***** End identify Modal *****/

/* .box-price-footer{
  position: absolute;
  bottom: 2%;
  left: 10%;
  right: 10%;
} */

/* check my id */
.store_icons {
  justify-content: center;
  padding: 26px !important;
}