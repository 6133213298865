@font-face {
  font-family: "SF Pro Display";
  src: url("SFProDisplay-Regular.woff2") format("woff2"),
    url("SFProDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("SFProDisplay-Bold.woff2") format("woff2"),
    url("SFProDisplay-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("SFProDisplay-Medium.woff2") format("woff2"),
    url("SFProDisplay-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("SFProDisplay-Semibold.woff2") format("woff2"),
    url("SFProDisplay-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("SFProDisplay-Thin.woff2") format("woff2"),
    url("SFProDisplay-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("SFProDisplay-Light.woff2") format("woff2"),
    url("SFProDisplay-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
